/**
 * PIKA: context
 */
import React, { createContext, useContext } from "react";
import Cookies from "universal-cookie";
import userStatusActions from "../redux/actions/userStatusActions";
import assistantsActions from "../redux/actions/assistantsActions.js";
import assistantconfigActions from "../redux/actions/assistantconfigActions.js";
import userGroupActions from "../redux/actions/userGroupActions";
import selectedAssistantActions from "../redux/actions/selectedAssistantActions";
import store from "../redux/store.js";
import i18n from "../i18n";
import axios from "axios";
import { updateAnswerItems } from "redux/reducers/answersReducers";
import { traceError } from "../helpers/utilities";
import lod_, { isEmpty } from "lodash";

const fAibrikContext = createContext();
// require("dotenv").config();
const endPoint = process.env.REACT_APP_AMBACK;
const cookies = new Cookies();

export const ProvideAuthFaibrik = props => {
	/**
	 * Check if the selected user has the given permission like 'write_answer'
	 * @param {string} perm
	 */

	const fAibrikStatus = {
		has: function (perm) {
			let perms = store.getState().userStatus.auth.user.perms;
			if (!perms) return false;
			return perms[perm];
		},

		fetching: function (user, assistantID, cb) {
			if (!user) {
				traceError(`fetching-No user to authorize`);
				return;
			}

			axios(endPoint + "/api/authorize", {
				headers: {
					Authorization: `Bearer ${user.token}`,
					UserEmail: user.email
				},
				method: "POST",
				data: {
					name: user.nickname,
					email: user.email
				}
			})
				.then(responseData => {
					responseData = responseData.data;
					if (responseData.authorized) {
						if (responseData.firstConnexion) {
							cb(true, responseData.name, responseData.surname);
						} else {
							cb(false);
						}
						user.perms = responseData.perms;
						user.fetched = true;

						let currentCookiesAssistant = cookies.get("fAIbrik.selectedAssistantID");
						let currentAssistant = assistantID || currentCookiesAssistant;

						if (
							lod_.isNil(currentAssistant) &&
							!lod_.isNil(responseData?.assistantIDs) &&
							responseData?.assistantIDs.length === 1
						) {
							currentAssistant = responseData?.assistantIDs[0];
						}

						//set current language
						if (!lod_.isNil(responseData?.language) && !lod_.isEmpty(responseData?.language)) {
							let newLanguage = responseData.language.toLowerCase();
							i18n.changeLanguage(newLanguage);
						}
						const assistantIDs = responseData?.assistantIDs;
						//update userStatus in redux store
						if (lod_.isNil(currentAssistant) || lod_.isEmpty(currentAssistant)) {
							store.dispatch(
								userStatusActions.addUserData(user, null, assistantIDs, function () {
									//update assistants
									store.dispatch(assistantsActions.updateAssistants(assistantIDs));
									store.dispatch({
										type: "ws/set_human_request",
										payload: false
									});

									store.dispatch(userStatusActions.hideSessionReleaseNote(false));
								})
							);
						} else {
							store.dispatch(
								userStatusActions.addUserData(user, currentAssistant, assistantIDs, function () {
									//update assistants
									store.dispatch(assistantsActions.updateAssistants(assistantIDs));
									let hideReleaseNotesForSession = sessionStorage.getItem("releaseNoteDisplayed");
									if (hideReleaseNotesForSession === undefined) {
										hideReleaseNotesForSession = false;
									}
									store.dispatch(
										userStatusActions.hideSessionReleaseNote(hideReleaseNotesForSession)
									);
									store.dispatch(
										selectedAssistantActions.updateSelectedAssistant(currentAssistant)
									);
									store.dispatch({
										type: "ws/set_human_request",
										payload: false
									});
									store.dispatch(assistantconfigActions.getConfigByAssistant(currentAssistant));
									store.dispatch(updateAnswerItems(currentAssistant));
									store.dispatch(userGroupActions.updateGroups(currentAssistant));
									store.dispatch(userGroupActions.updateUsersGroups(currentAssistant));
								})
							);
						}
					} else {
						//TODO : Display alert with error
						traceError(`fetching-Context authorization failed`);
					}
				})
				.catch(err => {
					traceError(`fetching-Context failed ${err.message}`);
				});
		}
	};

	const provideValues = {
		fAibrikStatus
	};

	return <fAibrikContext.Provider value={provideValues} {...props} />;
};

export const useFAibrikContext = () => useContext(fAibrikContext);
