/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import styles from "../../assets/jss/material-dashboard-pro-react/components/adminNavbarStyle";
import { Avatar, Box, Icon, List, ListItem, ListItemText, Typography } from "@material-ui/core";
import faibrik_white from "../../assets/img/logo/default/faibrik_white.png";
import { NavLink } from "react-router-dom";
import PowerSettingsNewOutlinedIcon from "@material-ui/icons/PowerSettingsNewOutlined";
import { useAuth0 } from "react-auth0-wrapper";
import Cookies from "universal-cookie";
import i18n from "i18n";
import { useSelector } from "react-redux";

const basicFavicon = "../../../public/fAIbrik_favicon.png";
const cookies = new Cookies();

const useStyles = makeStyles(theme => ({
	root: {
		width: "90%",
		"&:hover": {
			backgroundColor: "rgb(255, 255, 255, 0.2)"
		},
		borderRadius: "10px",
		cursor: "pointer"
	},
	nested: {
		paddingLeft: theme.spacing(4)
	}
}));

function LoginMenu(props) {
	const navBoardStyle = {
		position: "fixed",
		height: "calc(100% - 32px)", // Donne une hauteur totale de l'écran moins les marges (16px en haut et en bas)
		top: 16,
		left: 16,
		width: "250px",
		background: "linear-gradient(195deg, rgb(66, 66, 74), rgb(25, 25, 25))", // Dégradé linéaire
		color: "white",
		borderRadius: "0.75rem"
	};
	const { logout } = useAuth0();
	const classes = useStyles();
	const user = useSelector(state => state.userStatus.auth.user);
	function logOutUser() {
		cookies.remove("fAIbrik.selectedAssistantID");
		logout({ returnTo: window.location.origin });
	}

	return (
		<Box style={navBoardStyle}>
			<Box
				style={{
					display: "flex",
					flexDirection: "column",
					height: "100%"
				}}
			>
				<Box
					style={{ display: "flex", flexDirection: "row", alignItems: "center", padding: "8px" }}
				>
					<img src={faibrik_white} alt="marketplace-logo" width="50px" />
					<Typography style={{ fontWeight: "bold" }}>COCKPIT</Typography>
				</Box>
				<hr
					style={{
						height: "2px",
						width: "100%",
						border: "none",
						background:
							"linear-gradient(to right, transparent, rgba(255,255,255,0.6), transparent)",
						opacity: 0.5
					}}
				/>
				<Box
					style={{
						display: "flex",
						flexDirection: "column",
						justifyContent: "space-between",
						height: "100%"
					}}
				>
					{/* BOX USE FOR ROUTING AREA */}
					<List
						component="nav"
						style={{ display: "flex", justifyContent: "center", paddingBottom: "6%" }}
					>
						<ListItem className={classes.root}>
							<Avatar style={{ marginRight: "5%" }} alt={user?.given_name} src="" />

							<ListItemText
								disableTypography={true}
								style={{ color: "white" }}
								primary={user?.given_name + " " + user?.family_name}
							/>
						</ListItem>
					</List>

					<List
						component="nav"
						style={{ display: "flex", justifyContent: "center", marginBottom: "8%" }}
					>
						<ListItem className={classes.root} onClick={() => logOutUser()}>
							<Icon style={{ color: "white", marginRight: "5%" }}>
								<PowerSettingsNewOutlinedIcon />
							</Icon>
							<ListItemText
								disableTypography={true}
								style={{ color: "white" }}
								primary={i18n.t("manageButton.logout")}
							/>
						</ListItem>
					</List>
				</Box>
			</Box>
		</Box>
	);
}

export default LoginMenu;
