import { Box, ListItem, Paper, Snackbar, Tooltip } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import PlayArrowOutlinedIcon from "@material-ui/icons/PlayArrowOutlined";
import MuiAlert from "@material-ui/lab/Alert";
import React, { useRef, useState } from "react";
import { withTranslation } from "react-i18next";
import { connect, useDispatch } from "react-redux";
import { bindActionCreators, compose } from "redux";
import cockpitActions from "redux/actions/cockpitActions";
import engageActions from "redux/actions/engageActions";
import "../pages/Cockpit/CockpitStyleJs/TabAction.css";
import lod_ from "lodash";

import ExportConversationToGedDialog from "./actions/HSH/ExportConversationToGed/ExportConversationToGedDialog";
import { ExportConversationToGedDialogSchema } from "./actions/HSH/ExportConversationToGed/ExportConversationToGedDialogSchema";
import UpdateOrderDialog from "./actions/Vinatis/UpdateOrder/UpdateOrderDialog";
import i18n from "i18n";
import SearchDataCrmInput from "./actions/SearchDataCrmInput";
import { getRcvSntAtt, getRcvSntAttAll } from "pages/Cockpit/CockpitTabInfo/CockpitTabInfo";
import { createEngageBusiness } from "./actions/business/createEngage";
import { errorMsg } from "redux/reducers/snackMsgsReducers";
import SearchDataInConversationSelect from "./actions/searchDataInConversationSelect";
import SearchDataCrmInputCreateAffair from "./actions/HSH/SearchDataCrmInputCreateAffair";
import SIEQuoteManagerDialog from "./actions/SIE/SIEQuoteManagerDialog";
import SearchDataEnrichInput from "./actions/SearchDataEnrichInput";
import CreationLitige from "./actions/eminza/CreationLitige";
import CloseTransferCall from "./actions/cilumine/CloseTransferCall";
import ActionFormDynamic from "./actions/actionForm/ActionFormDynamic";
import ScrappingDataInput from "./actions/ScrappingDataInput";
import FormModerationAvis from "./actions/avisVerifie/FormModerationAvis";
import FormChangeStatusReturn from "./actions/pinkConnect/FormChangeStatusReturn";

function TabAction(props) {
	const [snackbarStatus, setSnackbarStatus] = React.useState({ open: false, message: "" });
	const [displaySpinner, setDisplpaySpinner] = useState(false);
	const [actionModal, setActionModal] = useState();
	const [actionModalOpen, setActionModalOpen] = useState(false);

	const dispatch = useDispatch();

	const actionRef = useRef();

	let fID = props.selectedConversation.header.fID;
	let language = props.userStatus.auth.user.language;
	let isArchived;
	if (props.selectedConversation.header.state === "archived") {
		isArchived = true;
	} else {
		isArchived = false;
	}

	const handleCloseSnackbar = (event, reason) => {
		if (reason === "clickaway") {
			return;
		}
		setSnackbarStatus({ open: false, message: "" });
	};

	const actionSucceed = expectFile => {
		setDisplpaySpinner(false);
		if (expectFile) {
			setSnackbarStatus({ open: true, message: props.t("COC.DownloadSucceed") });
		} else {
			setSnackbarStatus({ open: true, message: props.t("COC.ActionSucceed") });
		}
	};

	const actionFailed = (errorCode, actionDialog) => {
		setDisplpaySpinner(false);

		if (errorCode === "ERROR_ACTION__NO_ATTACHMENTS_FOUND") {
			setSnackbarStatus({
				open: true,
				message: props.t(`TabAction.${errorCode}`),
				severity: "warning"
			});
		} else {
			if (errorCode) {
				setSnackbarStatus({
					open: true,
					message: props.t(`TabAction.${errorCode}`),
					severity: "error"
				});
			} else {
				setSnackbarStatus({
					open: true,
					message: props.t(`TabAction.downloadFailed`),
					severity: "error"
				});
			}
		}

		if (actionDialog) {
			// Reopen dialog if fail
			setActionModalOpen(true);
		}
	};

	const callApiActions = () => {
		if (!displaySpinner) {
			setDisplpaySpinner(true);
			let action = props.action;
			let actionObject = props.actionObject;
			let events = props.events;
			let extraData = {};

			let expectFile = true;
			let openDialog = false; // If we need to open a dialog to enter some values
			let noCloseModal = true; // If we need to close the modal after the action

			/**
			 * If action has "openDialog" in its config, we need to open a dialog
			 * This boolean is used to open the dialog, it's avoid
			 * lot of process to open a dialog for each action under this condition
			 */
			if (actionObject.config && actionObject.config.openDialog) {
				openDialog = true;
			}

			/**
			 * Pour faciliter la migration on duplique la clef qq jours. Il faudra changer tous les assistantConfig avec {'actions.action':'refreshContext'}
			 */
			let eventsOpenDialog = [
				"updateOrder",
				"createCase",
				"cilumineCloseTaskOutgoingCall",
				"cilumineCloseTaskCcExpired",
				"rfshScrapping"
			];
			if (action === "fireFaibrikEvent" && events) {
				if (eventsOpenDialog.some(element => events.includes(element))) {
					openDialog = true;
				}
				if (props?.actionObject?.config?.formDynamic === true) {
					openDialog = true;
				}
				extraData = {
					events: props.events
				};
				if (props.actionObject.config) {
					extraData = { ...extraData, ...props.actionObject.config };
				}
				expectFile = false;
			}

			if (action === "forwardMessageToList") {
				expectFile = false;
			}

			/**
			 * All possible actions that require to open a dialog
			 */
			openDialog =
				[
					"HSHexportConversationToGed",
					"searchDataCrm",
					"searchDataEnrich",
					"HSHsearchDataCrm",
					"searchDataInConversation",
					"SIEquoteManager"
				].includes(action) || openDialog;

			/**
			 * All possible actions that require to not close the modal
			 */
			noCloseModal = ["searchDataCrm", "searchDataEnrich"].includes(action) || noCloseModal;

			if (openDialog) {
				// Some actions require to open a modal to enter some values
				openActionDialog(action, events);
				// Hide the menu, used to display modals
				if (props.popoverCloseMenu && !noCloseModal) {
					props.popoverCloseMenu();
				}
			} else {
				if (typeof props.popoverActionsHandleClose === "function") {
					// In some cases TabAction is a popover. On action close it
					props.popoverActionsHandleClose();
				}

				if (action === "copyConv") {
					let copyLocal = JSON.parse(localStorage.getItem("presse-papier")) || [];
					let title = "";
					let first = props.selectedConversation.contact?.first || "";
					let last = props.selectedConversation.contact?.last || "";
					let contact = first + " " + last;
					let isArchivedConv = false;

					const { rcvAtt, sntAtt } = getRcvSntAtt(props.selectedConversation);
					const { rcvAttAll, sntAttAll } = getRcvSntAttAll(
						rcvAtt,
						sntAtt,
						{ assistantID: props.selectedConversation.header.assistantID },
						props.selectedConversation
					);

					if (props.selectedConversation?.header.state === "archived") {
						isArchivedConv = true;
					}
					if (props.selectedConversation?.messages?.length > 0) {
						const dernierElement =
							props.selectedConversation.messages[props.selectedConversation.messages.length - 1];

						title = dernierElement.payload?.payload?.subject;
					}

					if (
						!props.selectedConversation.contact?.first &&
						!props.selectedConversation.contact?.last &&
						props.selectedConversation.contact?.email
					) {
						contact = props.selectedConversation.contact?.email;
					}

					copyLocal.push({
						fID,
						type: "conversation",
						assistantID: props.selectedConversation?.header?.assistantID,
						date: props.selectedConversation?.header?.start_ts,
						code: props.selectedConversation?.header?.channel,
						title,
						contact,
						rcvAttAll,
						sntAttAll,
						isArchivedConv
					});

					localStorage.setItem("presse-papier", JSON.stringify(copyLocal));
					dispatch(cockpitActions.setCopyClipboard());

					return;
				}

				if (action === "createEngage") {
					const businessResult = createEngageBusiness(props.selectedConversation, actionObject);
					if (!businessResult.success) {
						if (businessResult.warn) {
							dispatch(errorMsg(businessResult.warn));
						}
						return;
					}
					dispatch(
						engageActions.createEngageConversation(
							businessResult.channelCode,
							businessResult.initialDraft,
							fID
						)
					);
					return;
				}

				// The new actionFront action will use actionFrontV2 system from auth-dev / NYO
				const newActionFrontAction = [
					"generateQuoteNumber",
					"SIEloadCustomerAndSellers",
					"HSHCreateAffair",
					"SIEloadQuoteInfo",
					"analyseCase",
					"crmUpdateRessource",
					"uploadFileInRPA",
					"analyseLlm",
					"unarchiveConversationArchived",
					"analyseDocumentLlm",
					"analyseUnderstand",
					"unsetKeyDictionnary"
				];

				const externalId = props.userStatus.auth.user.external_id;

				if (newActionFrontAction.includes(action)) {
					const data = {
						header: {
							assistantID: props.selectedConversation?.header?.assistantID,
							fID: props.selectedConversation?.header?.fID,
							actionName: actionObject.name
						},
						payload: actionObject?.config ?? {}
					};
					if (action === "HSHCreateAffair") {
						data.payload.externalId = externalId;
					}
					if (action === "uploadFileInRPA") {
						if (
							!props.selectedConversation?.contact.last ||
							!props.selectedConversation?.contact?.first
						) {
							dispatch(errorMsg(i18n.t("messagesAction.errorUploadInRPAContact")));
							return;
						}
						data.payload.contact = props.selectedConversation?.contact;
						data.payload.isArchived = isArchived;
						data.payload.language = language;
					}
					if (action === "crmUpdateRessource") {
						data.payload.ressource = actionObject.config.ressource;
						data.payload.crud = actionObject.config.crud;
					}
					if (action === "analyseLlm") {
						data.payload.llmProcessCode = actionObject.config.llmProcessCode;
					}
					if (action === "analyseDocumentLlm") {
						data.payload.analyseDocumentLlmCode = actionObject.config.code;
					}
					if (action === "unsetKeyDictionnary") {
						data.payload.resetFields = actionObject.config?.resetFields || [];
					}
					dispatch(cockpitActions.launchActionFrontV2(action, data));
					if (typeof props.popoverActionsHandleClose === "function") {
						props.popoverActionsHandleClose();
					}
					setDisplpaySpinner(false);
					return;
				}

				const { rcvAtt, sntAtt } = getRcvSntAtt(props.selectedConversation);
				const { rcvAttAll, sntAttAll } = getRcvSntAttAll(
					rcvAtt,
					sntAtt,
					{ assistantID: props.selectedConversation.header.assistantID },
					props.selectedConversation
				);

				// Other actions can be fired directly on older route /api/v1/callTabAction
				let actionDialog = false;
				props.cockpitActions.doAnAction(
					fID,
					isArchived,
					language,
					action,
					rcvAttAll,
					sntAttAll,
					extraData,
					expectFile,
					props.queryParams,
					actionDialog,
					actionSucceed,
					actionFailed,
					props.selectedConversation
				);
			}
		}
	};

	// Modal actions, some action require additionnal parameters

	/**
	 * For some actions, we need to open a modal, this is doing the job of submiting the form of it.
	 */
	const handleActionDialogSubmit = (data, action) => {
		setActionModalOpen(false);
		setDisplpaySpinner(false);
		if (typeof props.popoverActionsHandleClose === "function") {
			props.popoverActionsHandleClose();
		}

		if (action === "searchDataCrm") {
			// Specific action is managed at component level
			return;
		}

		if (action === "searchDataEnrich") {
			// Specific action is managed at component level
			return;
		}

		if (action === "searchDataInConversation") {
			// Specific action is managed at component level
			return;
		}

		let extraData = lod_.cloneDeep(data);

		/**
		 * HSH Specific
		 */
		if (action === "HSHexportConversationToGed") {
			if (extraData.affairNumber && !extraData.affairNumber.startsWith("PL")) {
				extraData.affairNumber = `PL/${extraData.affairNumber}`;
			}
		}

		if (action === "fireFaibrikEvent") {
			if (props.events) {
				extraData.events = props.events;
				if (props?.actionObject?.config) {
					extraData = { ...extraData, ...props.actionObject.config };
				}
			}
		}

		/**
		 * Process actions type actionFrontV2
		 */
		if (action === "actionFrontV2") {
			// Action's data
			const data = {
				header: {
					assistantID: props.selectedConversation?.header?.assistantID,
					fID: props.selectedConversation?.header?.fID
				},
				payload: extraData
			};
			// Get actionFront to execute from action config
			const actionFront = props?.actionObject?.config?.action;
			if (actionFront) {
				// Dispatch action
				dispatch(cockpitActions.launchActionFrontV2(actionFront, data));
			}
			return;
		}

		const { rcvAtt, sntAtt } = getRcvSntAtt(props.selectedConversation);
		const { rcvAttAll, sntAttAll } = getRcvSntAttAll(
			rcvAtt,
			sntAtt,
			{ assistantID: props.selectedConversation.header.assistantID },
			props.selectedConversation
		);

		let expectFile = false;
		let actionDialog = true;
		props.cockpitActions.doAnAction(
			fID,
			isArchived,
			language,
			action,
			rcvAttAll,
			sntAttAll,
			extraData,
			expectFile,
			null,
			actionDialog,
			actionSucceed,
			actionFailed
		);
	};

	/**
	 * For some actions, we need to open a modal, this is doing the job of closing it.
	 */
	const handleActionDialogCancel = () => {
		setActionModalOpen(false);
		setDisplpaySpinner(false);
	};

	/**
	 * For some actions, we need to open a modal, this is doing the job of opening it.
	 * @param {string} action Name of the action
	 * @param {Array} events list of event to call
	 */
	const openActionDialog = (action, events = []) => {
		switch (action) {
			case "HSHexportConversationToGed": {
				/**
				 * HSH Specific
				 */
				let PLNbr = props.selectedConversation.context.tenant.PLNbr;
				let TOTIE_COD = props.selectedConversation.context.tenant.noTiers;
				let TOTIE_LIB = `${props.selectedConversation.contact.last} ${props.selectedConversation.contact.first}`;

				let affairNumber;

				try {
					affairNumber = PLNbr[0].split("PL/")[1];
				} catch (error) {
					affairNumber = "";
				}

				let incomingData = {
					affairNumber,
					TOTIE_COD,
					TOTIE_LIB
				};
				let loadSchema = ExportConversationToGedDialogSchema(props.t);
				setActionModal(
					<ExportConversationToGedDialog
						action={action}
						handleSubmit={handleActionDialogSubmit}
						handleClose={handleActionDialogCancel}
						incomingData={incomingData}
						schema={loadSchema.schema}
						uiSchema={loadSchema.uiSchema}
					/>
				);
				break;
			}
			case "fireFaibrikEvent": {
				if (events.includes("updateOrder")) {
					/**
					 * VINATIS Specific
					 */
					let orderID =
						props?.selectedConversation?.context?.commandID ||
						props?.selectedConversation?.context?.ticket?.commandID;
					let employeeID = props.userStatus.auth.user.external_id;

					if (!orderID) {
						setSnackbarStatus({
							open: true,
							message: props.t("TabAction.fireFaibrikEvent.vinatisNoCommand"),
							severity: "warning"
						});
						setDisplpaySpinner(false);
					} else if (!employeeID) {
						setSnackbarStatus({
							open: true,
							message: props.t("TabAction.fireFaibrikEvent.vinatisNoEmployeeId"),
							severity: "warning"
						});
						setDisplpaySpinner(false);
					} else {
						setActionModal(
							<UpdateOrderDialog
								action={action}
								orderID={orderID}
								employeeID={employeeID} // "596" is the test value
								handleSubmit={handleActionDialogSubmit}
								handleClose={handleActionDialogCancel}
							/>
						);
					}
				}

				if (events.includes("createCase")) {
					/**
					 * EMINZA Specific
					 */
					setDisplpaySpinner(false);

					setActionModal(
						<CreationLitige
							action={action}
							actionObject={props.actionObject}
							resumeFromSummary={props?.selectedConversation?.meta?.cockpit?.summary || ""}
							anchorEl={actionRef?.current}
							handleSubmit={handleActionDialogSubmit}
							handleClose={handleActionDialogCancel}
						/>
					);
				}

				if (props.actionObject?.config?.formDynamic === true) {
					setActionModal(
						<ActionFormDynamic
							action={action}
							actionObject={props.actionObject}
							handleSubmit={handleActionDialogSubmit}
							handleClose={handleActionDialogCancel}
						/>
					);
				}

				if (events.includes("rfshScrapping")) {
					setDisplpaySpinner(false);

					setActionModal(
						<ScrappingDataInput
							actionObject={props.actionObject}
							anchorEl={actionRef?.current}
							handleSubmit={handleActionDialogSubmit}
							handleClose={handleActionDialogCancel}
						/>
					);
				}

				break;
			}
			case "searchDataCrm": {
				setDisplpaySpinner(false);

				setActionModal(
					<SearchDataCrmInput
						actionObject={props.actionObject}
						anchorEl={actionRef?.current}
						handleSubmit={handleActionDialogSubmit}
						handleClose={handleActionDialogCancel}
					/>
				);
				break;
			}

			case "searchDataEnrich": {
				setDisplpaySpinner(false);

				setActionModal(
					<SearchDataEnrichInput
						actionObject={props.actionObject}
						anchorEl={actionRef?.current}
						handleSubmit={handleActionDialogSubmit}
						handleClose={handleActionDialogCancel}
					/>
				);
				break;
			}
			case "HSHsearchDataCrm": {
				setDisplpaySpinner(false);

				setActionModal(
					<SearchDataCrmInputCreateAffair
						actionObject={props.actionObject}
						anchorEl={actionRef?.current}
						handleSubmit={handleActionDialogSubmit}
						handleClose={handleActionDialogCancel}
					/>
				);
				break;
			}
			case "searchDataInConversation": {
				let listFields = lod_.get(
					props.selectedConversation,
					props.actionObject.config?.listFields
				);

				if (!listFields) {
					setSnackbarStatus({
						open: true,
						message: props.t(`TabAction.searchDataInConversation.noESIfields`),
						severity: "warning"
					});
					setDisplpaySpinner(false);
				} else if (!props.selectedConversation?.context?.affaire) {
					setSnackbarStatus({
						open: true,
						message: props.t(`TabAction.searchDataInConversation.noAffairType`),
						severity: "warning"
					});
					setDisplpaySpinner(false);
				} else {
					setDisplpaySpinner(false);
					setActionModal(
						<SearchDataInConversationSelect
							actionObject={props.actionObject}
							anchorEl={actionRef?.current}
							handleSubmit={handleActionDialogSubmit}
							handleClose={handleActionDialogCancel}
						/>
					);
				}
				break;
			}
			case "SIEquoteManager": {
				setActionModal(<SIEQuoteManagerDialog handleClose={handleActionDialogCancel} />);
				break;
			}
			// Action front V2
			case "actionFrontV2": {
				if (events.includes("cilumineCloseTransferCall")) {
					setActionModal(
						<CloseTransferCall
							action={action}
							actionObject={props.actionObject}
							handleSubmit={handleActionDialogSubmit}
							handleClose={handleActionDialogCancel}
						/>
					);
				} else if (events.includes("avisModeration")) {
					setDisplpaySpinner(false);

					setActionModal(
						<FormModerationAvis
							action={action}
							actionObject={props.actionObject}
							handleSubmit={handleActionDialogSubmit}
							handleClose={handleActionDialogCancel}
						/>
					);
				} else if (events.includes("changeStatusReturn")) {
					setDisplpaySpinner(false);

					setActionModal(
						<FormChangeStatusReturn
							action={action}
							actionObject={props.actionObject}
							handleSubmit={handleActionDialogSubmit}
							handleClose={handleActionDialogCancel}
						/>
					);
				}
				break;
			}
		}

		setActionModalOpen(true);
	};

	const getActionsName = () => {
		// If action's name exist in translations files
		if (i18n.exists(`TabAction.${props.action}.${props?.events?.[0]}`)) {
			return props.t(`TabAction.${props.action}.${props.events[0]}`);
		}

		// If action has action.multipleAction (VDI for sending mails todifferent mailing lists)
		// If multipleAction is true, concatenate action's translation with description
		// (assistantConfig.description) to avoid actions with the same action with
		// multiple translations.
		if (props.multipleAction) {
			return props.t(`TabAction.${props.action}`) + " " + props.description;
		}

		// Return name which is in DB assistantConfig.actions.name
		return props.name;
	};

	return (
		<React.Fragment>
			<ListItem
				style={{ display: "flex", flexDirection: "row", padding: 0, marginTop: 5 }}
				ref={actionRef}
			>
				<Box width={1} onClick={() => callApiActions()}>
					<Tooltip title={props.name} placement="top">
						<Paper elevation={3}>
							<Box
								display="flex"
								p={1}
								alignItems="center"
								className="cardActions"
								// justifyContent="space-between"
							>
								<Box
									m={1}
									display="flex"
									marginLeft="5px"
									marginRight="10px"
									style={{ objectFit: "contain" }}
								>
									<div className="logoContainer">
										<img
											className="logoActions"
											alt={props.name}
											src={props.logo}
											style={{ objectFit: "contain" }}
										></img>
									</div>
								</Box>
								{/* <Tooltip title={props.name} placement="top"> */}
								<Box fontWeight="fontWeightSmall" flex="1">
									{getActionsName()}
								</Box>
								{/* </Tooltip> */}
								<Box fontWeight="fontWeightMedium" display="flex" justifySelf="">
									{displaySpinner ? (
										<CircularProgress size={20} />
									) : (
										<PlayArrowOutlinedIcon size="small" />
									)}
								</Box>
							</Box>
						</Paper>
					</Tooltip>
				</Box>

				{actionModalOpen && actionModal}
			</ListItem>
			<Snackbar
				anchorOrigin={{ vertical: "top", horizontal: "center" }}
				open={snackbarStatus.open}
				onClose={handleCloseSnackbar}
				autoHideDuration={5000}
			>
				<Alert
					onClose={handleCloseSnackbar}
					severity={snackbarStatus.severity ? snackbarStatus.severity : "info"}
				>
					{snackbarStatus.message}
				</Alert>
			</Snackbar>
		</React.Fragment>
	);
}

function Alert(props) {
	return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function mapStateToProps(state) {
	return state;
}

function mapDispatchToProps(dispatch) {
	return {
		cockpitActions: bindActionCreators(cockpitActions, dispatch)
	};
}

export default compose(withTranslation(), connect(mapStateToProps, mapDispatchToProps))(TabAction);
