/* eslint-disable react/display-name */
/* eslint-disable no-empty-pattern */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import Box from "@material-ui/core/Box";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import MenuList from "@material-ui/core/MenuList";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import React, { useEffect, useRef, useState } from "react";
import useStyles from "../../CockpitStyleJs/styleFilter";
import Tooltip from "@material-ui/core/Tooltip";
import C from "../../../../constants/cockpit.js";
import { useDispatch, useSelector } from "react-redux";
import i18n from "i18n";
import { Button, MenuItem } from "@material-ui/core";
import Menu from "@material-ui/core/Menu";
import ListItemIcon from "@material-ui/core";
import ListItemText from "@material-ui/core/ListItemText";
import { GridFilterAltIcon } from "@material-ui/data-grid";
import GetApp from "@material-ui/icons/GetApp";
import { Clear, Publish } from "@material-ui/icons";
import userStatusActions from "../../../../redux/actions/userStatusActions";
import SweetAlert from "react-bootstrap-sweetalert";
import { infoMsg } from "redux/reducers/snackMsgsReducers";
import { errorMsg } from "redux/reducers/snackMsgsReducers";
import PeopleIcon from "@material-ui/icons/People";
import { setApplyFiltersToUsersOpenDialog } from "redux/reducers/filtersReducers";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import HistoryIcon from "@material-ui/icons/History";
/**
 *
 * @returns
 */
export const FiltersActions = ({ setOpenDialog, setOpenDialogView }) => {
	const dispatch = useDispatch();
	const classes = useStyles();
	const {
		convIntentFilter,
		convUserFilter,
		convPriorityFilter,
		convSentimentFilter,
		convSubstateFilter,
		convLanguageFilter,
		convChannelFilter,
		convAscFilter,
		convSupervisorFilter,
		convAgentFilter,
		convGroupFilter,
		convConnectedFilter,
		convSearchFilter,
		convStartDateFilter,
		convEndDateFilter
	} = useSelector(state => state.cockpit);
	const { auth } = useSelector(state => state.userStatus);
	const selectedAssistantID = useSelector(state => state.selectedAssistantID);
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [hoveredActions, setHoveredActions] = useState(false);
	const [openActions, setOpenActions] = useState(false);
	const prevOpenActions = useRef(openActions);
	const anchorRefActions = useRef(null);

	useEffect(() => {
		if (auth && auth.user) {
			handleLoadFilters();
		}
	}, [auth, selectedAssistantID]);

	/**
	 *
	 */
	useEffect(() => {
		if (hoveredActions) {
			const timer = hoveredActions && setTimeout(handleToggleActions, 100);
			return () => {
				clearTimeout(timer);
			};
		} else {
			setOpenActions(false);
		}
	}, [hoveredActions]);

	/**
	 *
	 */
	useEffect(() => {
		if (prevOpenActions.current === true && openActions === false) {
			anchorRefActions.current.focus();
		}
		prevOpenActions.current = openActions;
	}, [openActions]);

	/**
	 *
	 */
	const handleToggleActions = () => {
		setOpenActions(prevOpen => !prevOpen);
	};

	/**
	 *
	 * @param {*} event
	 * @returns
	 */
	const handleCloseActions = event => {
		if (anchorRefActions.current && anchorRefActions.current.contains(event.target)) {
			return;
		}
		setOpenActions(false);
	};

	/**
	 *
	 * @param {*} event
	 */
	function handleListKeyDownActions(event) {
		if (event.key === "Tab") {
			event.preventDefault();
			setOpenActions(false);
		}
	}

	const handleClearFilters = () => {
		dispatch({ type: C.RESET_FILTERS });
		setOpenActions(false);
		setHoveredActions(false);
	};

	const handleLoadFilters = () => {
		let filtersSaved = auth.user.cockpitConvFiltersSaved?.filter(
			filter => filter.assistantID === selectedAssistantID
		);
		if (filtersSaved && filtersSaved.length === 1) {
			dispatch({
				type: C.LOAD_FILTERS,
				payload: filtersSaved[0]
			});
		}
		setOpenActions(false);
		setHoveredActions(false);
	};
	const handleSaveFilters = () => {
		let userToUpdate;
		if (auth.user.cockpitConvFiltersSaved) {
			userToUpdate = {
				cockpitConvFiltersSaved: [
					...auth.user.cockpitConvFiltersSaved.filter(
						filter => filter.assistantID !== selectedAssistantID
					),
					{
						assistantID: selectedAssistantID,
						defaultListDisplayOrder:
							selectedAssistantID?.cockpit?.conv_list_display_order &&
							selectedAssistantID?.cockpit?.conv_list_display_order === "asc"
								? false
								: true,
						activeSupervisorFilter: selectedAssistantID?.cockpit?.ongoing_list_display_mine
							? false
							: true,
						filters: {
							convIntentFilter,
							convUserFilter,
							convPriorityFilter,
							convSentimentFilter,
							convSubstateFilter,
							convLanguageFilter,
							convChannelFilter,
							convAscFilter,
							convSupervisorFilter,
							convAgentFilter,
							convGroupFilter,
							convConnectedFilter,
							convSearchFilter,
							convStartDateFilter,
							convEndDateFilter
						}
					}
				]
			};
		} else {
			userToUpdate = userToUpdate = {
				cockpitConvFiltersSaved: [
					{
						assistantID: selectedAssistantID,
						defaultListDisplayOrder:
							selectedAssistantID?.cockpit?.conv_list_display_order &&
							selectedAssistantID.cockpit.conv_list_display_order === "asc"
								? false
								: true,
						filters: {
							convIntentFilter,
							convUserFilter,
							convPriorityFilter,
							convSentimentFilter,
							convSubstateFilter,
							convLanguageFilter,
							convChannelFilter,
							convAscFilter,
							convSupervisorFilter,
							convAgentFilter,
							convGroupFilter,
							convConnectedFilter,
							convSearchFilter,
							convStartDateFilter,
							convEndDateFilter
						}
					}
				]
			};
		}
		dispatch(
			userStatusActions.saveUserdata(
				userToUpdate,
				() => {
					dispatch(infoMsg(i18n.t("COC.filtersSaved")));
				},
				() => {
					dispatch(errorMsg(i18n.t("COC.errorFiltersSaved")));
				}
			)
		);
		setOpenActions(false);
		setHoveredActions(false);
	};

	return (
		<Tooltip title={i18n.t("COC.filterActions")} placement="top">
			<Box
				className={classes.filterButton}
				mt={1}
				onMouseEnter={() => setHoveredActions(true)}
				onMouseLeave={() => setHoveredActions(false)}
			>
				<ToggleButton
					aria-haspopup="menu"
					aria-controls={openActions ? "menu-list-grow-actions" : undefined}
					ref={anchorRefActions}
					value={openActions}
					size="small"
				>
					<GridFilterAltIcon fontSize="small" />
				</ToggleButton>
				<Popper
					open={openActions}
					anchorEl={anchorRefActions.current}
					className={classes.dropDown}
					role={undefined}
					transition
					disablePortal
				>
					{() => (
						<ClickAwayListener onClickAway={handleCloseActions}>
							<MenuList
								mt={0}
								mb={0}
								pt={0}
								autoFocusItem={openActions}
								id="menu-list-grow-actions"
								onKeyDown={handleListKeyDownActions}
							>
								<Paper>
									<ToggleButtonGroup orientation="vertical" value="save" exclusive size="small">
										<Tooltip title={i18n.t("COC.lastViewConv")} placement="right">
											<ToggleButton value="save" onClick={() => setOpenDialogView(true)}>
												<HistoryIcon fontSize="small" />
											</ToggleButton>
										</Tooltip>
										<Tooltip title={i18n.t("COC.saveFilters")} placement="right">
											<ToggleButton value="save" onClick={() => handleSaveFilters()}>
												<Publish fontSize="small" />
											</ToggleButton>
										</Tooltip>

										<Tooltip title={i18n.t("COC.loadFilters")} placement="right">
											<ToggleButton value="load" onClick={() => handleLoadFilters()}>
												<GetApp fontSize="small" />
											</ToggleButton>
										</Tooltip>
										<Tooltip title={i18n.t("COC.clearFilters")} placement="right">
											<ToggleButton value="clear" onClick={() => handleClearFilters()}>
												<Clear fontSize="small" />
											</ToggleButton>
										</Tooltip>
										{(auth.user.role === "supervisor" || auth.user.role === "soloUser") && (
											<>
												<Tooltip title={i18n.t("COC.applyFilters")} placement="right">
													<ToggleButton
														value="clear"
														onClick={() => dispatch(setApplyFiltersToUsersOpenDialog(true))}
													>
														<PeopleIcon fontSize="small" />
													</ToggleButton>
												</Tooltip>

												{/* Actions d'assignation */}
												<Tooltip title={i18n.t("COC.manyActionTicket")} placement="right">
													<ToggleButton onClick={() => setOpenDialog(true)}>
														<ArrowForwardIcon fontSize="small" />
													</ToggleButton>
												</Tooltip>
											</>
										)}
									</ToggleButtonGroup>
								</Paper>
							</MenuList>
						</ClickAwayListener>
					)}
				</Popper>
			</Box>
		</Tooltip>
	);
};
