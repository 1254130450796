/* eslint-disable no-prototype-builtins */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import Box from "@material-ui/core/Box";
import React, { Fragment, memo, useState } from "react";
import lod_ from "lodash";
import i18n from "i18n";

import { CircularProgress, Grid, Typography } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";

const ItemCard = ({ name, role, loading = false, onClick = null }) => {
	const dispatch = useDispatch();
	const [loadingState, setLoading] = useState(loading);
	const [selected, setSelected] = useState(null);
	const handleClick = () => {
		if (loadingState) return;
		if (onClick) {
			setLoading(true);
			onClick();
		}
	};

	return (
		<Box
			onClick={handleClick}
			onMouseEnter={() => setSelected(true)}
			onMouseLeave={() => setSelected(false)}
			style={{
				padding: "16px",
				marginTop: "1%",
				color: "rgb(52, 71, 103)",
				cursor: "pointer",
				borderRadius: "16px",
				boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
				backgroundColor: selected === true ? "#93c5fd" : "#fff",
				transition: "background-color 0.3s, transform 0.2s",
				transform: selected === true ? "scale(1.01)" : "scale(1)"
			}}
		>
			<Box style={{ display: "flex", alignItems: "center" }}>
				<Grid container>
					<Grid item>
						<Box mb={0.5} lineHeight={1}>
							{!lod_.isNil(role) && (
								<Typography
									variant="body2"
									fontWeight="fontWeightMedium"
									color="textSecondary"
									textTransform="none"
									style={{ fontWeight: "bold" }}
								>
									{i18n.t("COC.ROLES." + role.toLowerCase())}
								</Typography>
							)}
						</Box>
						<Box>
							<Typography variant="h5" style={{ fontWeight: "bold" }}>
								{name}
							</Typography>
						</Box>
					</Grid>
				</Grid>

				{loadingState && (
					<Grid>
						<CircularProgress color="info" />
					</Grid>
				)}
			</Box>
		</Box>
	);
};

function SelectAssistant({ handleClickOpen }) {
	const assistants = useSelector(state => state.assistants);
	return (
		<Fragment>
			<Box
				style={{
					padding: "24px"
				}}
			>
				{lod_.isEmpty(assistants) ? (
					<Box py={3}>
						<Typography style={{ fontWeight: "bold" }} variant="h4">
							{i18n.t("COC.LOGIN.emptyAssistants")}
						</Typography>
					</Box>
				) : (
					<Box py={1}>
						<Typography style={{ fontWeight: "bold" }} variant="h5">
							{i18n.t("COC.LOGIN.choice")}
						</Typography>
					</Box>
				)}

				{!lod_.isNil(assistants) &&
					assistants
						.sort((a, b) => a.description.localeCompare(b.description))
						.map((assistant, index) => {
							return (
								<ItemCard
									name={assistant.description}
									role={assistant.role}
									onClick={() => {
										handleClickOpen(assistant.assistantID);
									}}
									key={index}
								/>
							);
						})}
			</Box>
		</Fragment>
	);
}

const MemoizedSelectAssistant = memo(SelectAssistant);
export default MemoizedSelectAssistant;
