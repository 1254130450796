/* eslint-disable consistent-return */
/* eslint-disable no-unused-vars */
/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint consistent-return: 2*/
/* eslint-disable no-mixed-operators */
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import i18n from "i18next";
import lod_ from "lodash";
import React, { useState, useEffect } from "react";
import { withTranslation } from "react-i18next";
import { connect, useDispatch } from "react-redux";
import { bindActionCreators, compose } from "redux";
import cockpitActions from "redux/actions/cockpitActions";
import engageActions from "redux/actions/engageActions";
import FormContact from "./FormContact";
import axios from "axios";
import MoreVertOutlinedIcon from "@material-ui/icons/MoreVertOutlined";
import AddIcon from "@material-ui/icons/Add";
import ContactActions from "./actions/ContactActions";
import { withStyles } from "@material-ui/core/styles";
import { deleteContactCRM, traceError } from "../../helpers/utilities";
import { Box, IconButton, Badge, Tooltip } from "@material-ui/core";
import EngageDropDown, { EngagePhone } from "../Engage/EngageDropDown";
import { api } from "redux/actions/api";
import { VisibilityOutlined } from "@material-ui/icons";
const endPoint = process.env.REACT_APP_AMBACK + "/api/v1/";
import ConfirmationDialog from "components/ConfirmationDialog";
import EditIcon from "@material-ui/icons/Edit";

function ContactEditDialog(props) {
	const [open] = React.useState(true);
	const [numberConversation, setNumberConversation] = useState(0);
	const [conversationsCold, setConversationsCold] = useState([]);
	const user = props.userStatus && props.userStatus.auth && props.userStatus.auth.user;
	const [openEngageDialog, setOpenEngageDialog] = useState(false);
	const [openRightMenu, setOpenRightMenu] = useState(false);
	const [anchorEl, setAnchorEl] = useState(null);
	const [possibleChannels, setPossibleChannels] = useState(null);
	const [getContact, setgetContact] = useState({});
	const dispatch = useDispatch();
	const [PI, setPI] = useState(false);
	const [hasPIValue, setHasPIValue] = useState(false);
	const [openPhone, setOpenPhone] = useState(false);
	const [selectedChannelCode, setSelectedChannelCode] = useState(null);

	/**
	 * Load channels available for engage
	 **/
	useEffect(() => {
		if (props.assistantconfig && props.assistantconfig.channels) {
			let channelByAssistant = props.assistantconfig.channels.filter(a => a.engage === true);
			if (channelByAssistant.length > 0) {
				if (channelByAssistant[0] !== undefined) {
					let engageableChannels = [];
					channelByAssistant.forEach(aChannel => {
						if (!engageableChannels.includes(aChannel.code)) {
							engageableChannels.push(aChannel);
						}
					});
					setPossibleChannels(engageableChannels);
					if (engageableChannels.length == 1) {
						props.cockpitActions.saveDefaultChannel(engageableChannels[0], false);
					}
				}
			}
		}
	}, [props.assistantconfig, props.assistantID]);

	/**
	 * Display dropDown to engage
	 **/
	const handleClickEngage = event => {
		if (Array.isArray(possibleChannels) && possibleChannels.length === 1) {
			if (possibleChannels[0]?.type === "PH") {
				setSelectedChannelCode(possibleChannels[0]?.code);
				setOpenPhone(true);
			} else {
				const initialDraft = {
					engageLinkContact: props?.contact?.email
				};
				handleEngageDialog(possibleChannels[0], initialDraft);
				// Redirect to cockpit page and wait to get an event to be on the conv
				window.location.href = "/admin/cockpit";
			}
		} else {
			return setAnchorEl(event.currentTarget);
		}
	};

	/**
	 * Close dropDown  which display channel
	 **/
	const handleCloseEngageDropDown = () => {
		setAnchorEl(null);
	};

	useEffect(async () => {
		await getContactConv();
		await getNumbersConversation();

		// eslint-disable-next-line
	}, []);

	/***
	 * CREATE a contact
	 **/
	const handleCreate = async contact => {
		if (user) {
			try {
				const res = await axios.post(endPoint + `${props.selectedAssistantID}/contact`, contact, {
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${user.token}`,
						UserEmail: user.email
					}
				});
				props.getContacts();
				props.handleCloseEditContactDialog();
			} catch (err) {
				traceError(`handleCreate-contact ${user} failed: ${err.message}`);

				// console.error("Contact not updated check error message :", err);
			}
		}
	};

	const hasPI = () => {
		setPI(true);
		handleClosePI();
	};

	useEffect(async () => {
		if (PI) {
			await getContactConv(true);
		}
		// eslint-disable-next-line
	}, [PI]);

	/***
	 * Get contact of conversation or list
	 **/
	const getContactConv = (PIValues = false) => {
		if (props.contact) {
			let FRUFromContact = props.contact.FRU;
			const assistantID = props.assistantconfig.assistantID;
			const dictionary = props.assistantconfig.dictionary.contact.items;
			const isEmpty = props.isEmpty;
			let PIAccess = PIValues;
			const data = { assistantID, PIAccess, dictionary, isEmpty };
			dispatch(
				api({
					method: "POST",
					type: "cockpit/contact/:FRU",
					url: `/api/v1/contact/${FRUFromContact}`,
					data: { data },
					onSuccess: res => {
						if (res?.hasPIValue) {
							delete res?.hasPIValue;
							setHasPIValue(true);
						}
						setgetContact(res);
					}
				})
			);
		}
	};

	/***
	 * Fetch numbers conversation on mount component
	 **/
	const getNumbersConversation = async () => {
		let ML_id = props.contact && props.contact.email ? props.contact.email : false;
		let FRUFromContact = props.contact && props.contact.FRU;

		if (user && (ML_id || FRUFromContact)) {
			let url = `${endPoint}${props.selectedAssistantID}/contact/${ML_id}/${FRUFromContact}`;

			const res = await axios.get(url, {
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${user.token}`,
					UserEmail: user.email
				},
				method: "POST",
				data: props.contact
			});

			let resCold;

			try {
				if (!lod_.isNil(FRUFromContact)) {
					let urlCold = `${endPoint}cold/conversations/contact/${FRUFromContact}`;

					let coldParams = {
						assistantID: props.selectedAssistantID
					};

					resCold = await axios.get(urlCold, {
						headers: {
							"Content-Type": "application/json",
							Authorization: `Bearer ${user.token}`,
							UserEmail: user.email
						},
						params: coldParams,
						method: "GET"
					});
				}
			} catch (error) {
				resCold = {};
			}

			// For info, totalConversation = totalConversationArchived + totalConversationNotArchived
			let totalConversation = res?.data?.totalNumberConversation || 0;
			let totalConversationCold = resCold?.data?.length || 0;
			if (Array.isArray(resCold.data)) {
				setConversationsCold(resCold.data);
			}
			setNumberConversation(totalConversation + totalConversationCold);
		}
	};

	/**
	 * Open engage
	 **/
	const handleEngageDialog = (channel, initialDraft) => {
		const channelCode = channel?.code;

		if (channelCode) {
			dispatch(engageActions.createEngageConversation(channelCode, initialDraft));
		}
	};

	const StyledBadge = withStyles(theme => ({
		badge: {
			top: "11px",
			right: "14px",
			border: `2px solid ${theme.palette.background.paper}`,
			padding: "0 4px",
			backgroundColor: "#545454"
		}
	}))(Badge);

	const handleOpenRightMenu = event => {
		setOpenRightMenu(!openRightMenu);
	};

	/***
	 * EDIT contact
	 **/
	const handleSave = async contact => {
		if (user) {
			try {
				contact = {
					...contact,
					email: contact.email.trim().toLowerCase() && contact.email.trim().toLowerCase(),
					ML_id: contact.email.trim().toLowerCase() && contact.email.trim().toLowerCase()
				};

				dispatch(
					cockpitActions.updateContact(
						contact,
						props.assistantconfig.assistantID,
						props.assistantconfig.dictionary
					)
				);

				props.getContacts();

				props.handleCloseEditContactDialog();
			} catch (err) {
				traceError(`handleSave-contact ${contact} failed: ${err.message}`);

				// console.error("Contact not updated check error message :", err);
			}
		}
	};

	/***
	 * DELETE contact
	 **/
	const handleDelete = async () => {
		if (user) {
			try {
				const res = deleteContactCRM(endPoint, props.selectedAssistantID, props.contactID, user);
				props.getContacts();
				props.handleCloseEditContactDialog();
			} catch (err) {
				traceError(`handleDelete-contact ${user} failed: ${err.message}`);

				// console.error("Contact not deleted check error message :", err);
			}
		}
	};

	/**
	 * Close modal ContactEditDialog
	 **/
	const handleClose = () => {
		props.handleCloseEditContactDialog();
	};

	/**
	 * Capitalize the first character of a string
	 **/
	const Capitalize = string => {
		try {
			return string.charAt(0).toUpperCase() + string.slice(1);
		} catch {
			return "";
		}
	};

	/**
	 *
	 * @returns ture is user has the contact:delete permission
	 */
	const hasContactDeletePerm = () => {
		if (!lod_.has(user, "perms.delete_contact")) {
			return false;
		}
		return user.perms.delete_contact;
	};
	/**
	 * Tell whether some usuall fields have some data
	 */
	const isAnonymous = contact => {
		try {
			return (
				!contact ||
				(
					"" +
					(contact.first || "") +
					(contact.last || "") +
					(contact.email || "") +
					(contact.company || "")
				).trim().length == 0
			);
		} catch (error) {
			return false;
		}
	};

	const [openConfirm, setOpenConfirm] = useState(false);

	const openDialogValidate = () => {
		if (hasPIValue) {
			setOpenConfirm(true);
		} else {
			hasPI();
		}
	};

	const handleClosePI = () => {
		setOpenConfirm(false);
	};

	const handleClosePhone = () => {
		setOpenPhone(false);
		setAnchorEl(null);
	};

	return (
		<React.Fragment>
			<Dialog onClose={handleClose} open={open} aria-labelledby="form-dialog-title" maxWidth={"lg"}>
				<Box display="flex" width="800px" flexDirection={openRightMenu ? "row" : "column"}>
					<Box display="flex" flexDirection={"column"} width={openRightMenu ? "80%" : "100%"}>
						<DialogTitle
							id="form-dialog-title"
							maxwidth={"md"}
							height="401px"
							style={{
								borderBottom: "1px solid",
								borderColor: "#d8d6d6"
							}}
						>
							<Grid
								container
								direction="row"
								justifyContent="space-between"
								alignItems="center"
								wrap="nowrap"
							>
								{props.mode === "edit"
									? `${i18n.t("CONTACT.editContact")} ${Capitalize(
											props?.contact?.first
										)} ${Capitalize(props?.contact?.last)}`
									: i18n.t("CONTACT.addContact")}{" "}
								{isAnonymous(props?.contact) ? i18n.t("CONTACT.Anonymous") : ""}
								<div>
									{!props.isEmpty && (
										<>
											{props.edit && (
												<Tooltip disabled={PI} title={i18n.t("TabInfo.showEditHiddenFields")}>
													<IconButton onClick={openDialogValidate}>
														<EditIcon />
													</IconButton>
												</Tooltip>
											)}
											{openConfirm && (
												<ConfirmationDialog
													open={openConfirm}
													handleClose={handleClosePI}
													title={i18n.t("TabInfo.showHiddenConfirmTitle")}
													message={i18n.t("TabInfo.showHiddenConfirm")}
													handleValidate={hasPI}
													cancel={i18n.t("COC.disagree")}
													validate={i18n.t("COC.agree")}
												/>
											)}
										</>
									)}
									{possibleChannels !== null && (
										<IconButton aria-label="close" onClick={handleClickEngage}>
											<AddIcon />
										</IconButton>
									)}
									{possibleChannels !== null && possibleChannels.length > 1 && anchorEl && (
										<EngageDropDown
											possibleChannels={possibleChannels}
											anchorEl={anchorEl}
											setAnchorEl={setAnchorEl}
											handleClose={handleCloseEngageDropDown}
											handleOpenEngageDialog={setOpenEngageDialog}
											initialDraft={{
												engageLinkContact: props?.contact?.email
											}}
										/>
									)}

									<EngagePhone
										handleClosePhone={handleClosePhone}
										openPhone={openPhone}
										dispatch={dispatch}
										channelCode={selectedChannelCode}
									/>

									<StyledBadge badgeContent={numberConversation} color="primary" showZero={true}>
										<IconButton aria-label="close" onClick={handleOpenRightMenu}>
											<MoreVertOutlinedIcon />
										</IconButton>
									</StyledBadge>
								</div>
							</Grid>
						</DialogTitle>

						<Grid container item xs={9} style={{ maxWidth: "100%" }}>
							<DialogContent style={{ overflowY: "hidden" }}>
								<Grid style={{ overflow: "hidden" }} container spacing={3}>
									{open && (
										<>
											<FormContact
												state={props}
												contactFiche={getContact}
												handleSave={handleSave}
												handleCreate={handleCreate}
												handleDelete={handleDelete}
												hasDeletePerm={hasContactDeletePerm}
												t={i18n.t}
												PI={PI}
												isEmpty={props.isEmpty}
												numberConversation={numberConversation}
												deleteContact={props.edit}
											/>
										</>
									)}
								</Grid>
							</DialogContent>
						</Grid>
					</Box>

					{openRightMenu && (
						<ContactActions
							contact={props.contact}
							numberConversation={numberConversation}
							handleCloseContactModal={handleClose}
							getContacts={props.getContacts}
							conversationsCold={conversationsCold}
						/>
					)}
				</Box>
			</Dialog>
		</React.Fragment>
	);
}

function mapStateToProps(state) {
	return state;
}

function mapDispatchToProps(dispatch) {
	return {
		cockpitActions: bindActionCreators(cockpitActions, dispatch)
	};
}

export default compose(
	withTranslation(),
	connect(mapStateToProps, mapDispatchToProps)
)(ContactEditDialog);
