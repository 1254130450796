import "assets/scss/material-dashboard-pro-react.scss?v=1.9.0";
import { createBrowserHistory } from "history";
import Admin from "./pages/Admin";
import React, { Suspense, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { Redirect, Router, Switch, Route } from "react-router-dom";
import config from "./auth_config.json";
import PrivateRoute from "./routes/PrivateRoute";
import { Auth0Provider } from "./react-auth0-wrapper";
import store from "./redux/store";
import "./assets/css/loader.css";
import "./assets/css/CockpitTabInfo.css";
import "./assets/css/Forms.css";
import "./assets/css/SunEditor.css";
import { ProvideAuthFaibrik } from "./context/Context";

import ErrorBoundary from "./helpers/ErrorBoundary";
// import { IdleTimerProvider } from "react-idle-timer"; // No longer in package.json
import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle
} from "@material-ui/core";
import i18n from "./i18n";
import SelectAssistant from "pages/SelectAssistant/SelectAssistant";

const hist = createBrowserHistory();
// require("dotenv").config();

const onRedirectCallback = appState => {
	window.history.replaceState(
		{},
		document.title,
		appState && appState.targetUrl ? appState.targetUrl : window.location.pathname
	);
};

const RefreshSession = () => {
	const [openRefresh, setOpenRefresh] = useState(false);

	const handleOnIdle = () => {
		setOpenRefresh(true);
	};

	const refresh = () => {
		setOpenRefresh(false);
		window.location.reload(); // Rafraîchit la page
	};

	return (
		// <IdleTimerProvider
		// timeout={10800000} // 3 HEURES d'inactivitées
		// onIdle={handleOnIdle}
		// >
		<Dialog
			open={openRefresh}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogTitle id="alert-dialog-title">{i18n.t("COC.refreshPageTitle")}</DialogTitle>
			<DialogContent>
				<DialogContentText id="alert-dialog-description">
					{i18n.t("COC.refreshPageMsg")}
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button color="primary" variant="contained" onClick={() => refresh()}>
					{i18n.t("COC.refreshPage")}
				</Button>
			</DialogActions>
		</Dialog>
		// </IdleTimerProvider>
	);
};

ReactDOM.render(
	<ErrorBoundary>
		<RefreshSession />
		<Auth0Provider
			domain={config.domain}
			client_id={config.clientId}
			redirect_uri={config.redirect_uri}
			audience={config.audience}
			onRedirectCallback={onRedirectCallback}
		>
			<Provider store={store}>
				<ProvideAuthFaibrik>
					<Suspense
						fallback={
							<div className="loader">
								<div className="circle"></div>
								<div className="circle"></div>
								<div className="circle"></div>
							</div>
						}
					>
						<Router history={hist}>
							<Switch>
								<PrivateRoute path="/" component={Admin} />
							</Switch>
						</Router>
					</Suspense>
				</ProvideAuthFaibrik>
			</Provider>
		</Auth0Provider>
	</ErrorBoundary>,
	document.getElementById("root")
);

/* if (window.Cypress) {
  window.store = store
} */
