/* eslint-disable no-unused-vars */
import C from "../../constants/assistantsConfig";
import axios from "axios";
import store from "../store.js";
import { redirectCockpitUrlIfNeeded } from "../../helpers/redirectCockpitUrl";

const endPoint = process.env.REACT_APP_AMBACK;
const actions = {
	getConfigByAssistant: function (assistantID) {
		let user = store.getState().userStatus.auth.user;
		let parts = [
			"account",
			"actions",
			"APIs",
			"bot",
			"channels",
			"cockpit",
			"COLD",
			"dictionary",
			"general",
			"intents",
			"languages",
			// "limit", ** KEVIN 10/03/2025 comment this to not get this attribute because not use in cockpit now all in assistantConfig.cockpit ***
			"understand"
		];
		if (user) {
			return dispatch => {
				const headers = {
					"Content-Type": "application/json",
					Authorization: `Bearer ${user.token}`,
					UserEmail: user.email
				};
				const data = {
					email: user.email,
					assistantID: assistantID,
					parts: parts
				};
				axios
					.post(endPoint + "/api/v1/assistantConfig", data, { headers: headers })
					.then(result => {
						// TODO : PIKA -> Why doing so, it'll computed anyhow by the back
						// dispatch({
						// 	type: "ws/set_human_request",
						// 	payload: true
						// });
						redirectCockpitUrlIfNeeded(result);
						const assistantsDetails = result.data;
						dispatch({
							type: C.GET_CONFIG,
							payload: assistantsDetails
						});
					})
					.catch(err => {
						// console.error("Backend replied with error", err);
					});
			};
		}
	}
};

export default actions;
