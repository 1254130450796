/* eslint-disable react/prop-types */
import Box from "@material-ui/core/Box";
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import i18n from "i18n";
import {
	Button,
	Card,
	CardContent,
	CardHeader,
	FormControl,
	FormControlLabel,
	Radio,
	RadioGroup,
	Tooltip,
	Typography
} from "@material-ui/core";
import { getLabelOf } from "./utilities";
import lod_ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { errorMsg } from "redux/reducers/snackMsgsReducers";
import cockpitActions from "../redux/actions/cockpitActions";
import { DataGrid } from "@material-ui/data-grid";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { fr } from "date-fns/locale";
import DateFnsUtils from "@date-io/date-fns";

const useStyles = makeStyles(theme => ({
	root: {
		width: "100%",
		display: "flex",
		flexDirection: "column",
		marginBottom: "10px",
		marginTop: "10px",
		borderRadius: "15%"
	},
	heading: {
		fontSize: theme.typography.pxToRem(15),
		display: "flex",
		flexDirection: "row",
		alignItems: "center"
	},
	secondaryHeading: {
		fontSize: theme.typography.pxToRem(15),
		color: "black"
	},
	AccordionDetails: {
		marginBottom: "10px",
		fontSize: theme.typography.pxToRem(15)
	}
}));

const checkType = type => {
	if (!["email", "string", "number", "date", "phone", "boolean"].includes(type)) {
		return false;
	} else {
		return true;
	}
};

export default function ProcessCall({ conversation, assistantConfig }) {
	const classes = useStyles();
	const [processCall, setProcessCall] = useState({});
	const [rowSelected, setRowSelected] = useState([]);
	const [dateForAction, setDateForAction] = useState(null);
	const [valueContext, setValueContext] = useState([]);
	const [columns, setColumns] = useState([]);
	const [dictionary, setDictionary] = useState({});
	const { userStatus } = useSelector(state => state);
	const [language] = useState(userStatus?.auth?.user?.language?.toLowerCase() ?? "fr");

	const dispatch = useDispatch();

	const buildTable = dictionary => {
		let buildColumns = [{ field: "id", headerName: "ID", sortable: false, width: 90 }];
		Object.keys(dictionary.items)
			.sort((a, b) => dictionary.items[a]?.order - dictionary.items[b]?.order)
			.map((child, key) => {
				if (
					checkType(dictionary.items[child]?.type) &&
					dictionary.items[child]?.active === true &&
					dictionary.items[child]?.display === true
				) {
					const headerName = lod_.isNil(dictionary.items[child]?.label[language])
						? child
						: dictionary.items[child]?.label[language];

					buildColumns.push({
						field: child,
						headerName,
						flex: 2,
						sortable: false,
						renderCell: params => (
							<Tooltip title={params.value} arrow>
								<span
									style={{
										overflow: "hidden",
										textOverflow: "ellipsis",
										whiteSpace: "nowrap",
										display: "block"
									}}
								>
									{params.value}
								</span>
							</Tooltip>
						),
						type: dictionary.items[child]?.type
					});
				}
			});
		setColumns(buildColumns);
	};

	useEffect(() => {
		let channel = assistantConfig?.channels.find(
			c => c.code === conversation?.header?.extra?.channelCode
		);

		if (
			!lod_.isNil(channel) &&
			!lod_.isNil(channel?.callProcess) &&
			!lod_.isEmpty(channel?.callProcess)
		) {
			let processObject = channel.callProcess.find(c => c.codeIntent === conversation?.meta?.topic);

			if (
				!lod_.isNil(processObject) &&
				!lod_.isEmpty(processObject) &&
				!lod_.isNil(processObject?.actionsProcess?.codeDictionary)
			) {
				if (!lod_.isNil(processObject?.actionsProcess?.pathDictionary)) {
					let values = lod_.get(
						conversation.context,
						processObject?.actionsProcess?.pathDictionary
					);
					if (!lod_.isNil(values) && lod_.isArray(values)) {
						setValueContext(values);
					} else {
						setValueContext([]);
					}
				}

				const onSuccess = res => {
					if (!lod_.isNil(res?.payload?.dictionary) && !lod_.isEmpty(res?.payload?.dictionary)) {
						setProcessCall(processObject);
						setDictionary(res.payload.dictionary);
						buildTable(res.payload.dictionary);
					} else {
						setProcessCall({});

						setDictionary({});
					}
				};

				const onFailure = res => {
					dispatch(errorMsg(i18n.t(`COC.actionForm.noDicoFoundAction`)));
				};

				dispatch(
					cockpitActions.getDictionnaryByCode(
						{
							assistantID: conversation?.header.assistantID,
							codeDictionary: processObject?.actionsProcess?.codeDictionary
						},
						onSuccess,
						onFailure
					)
				);
			} else {
				setProcessCall({});
			}
		} else {
			setProcessCall({});
		}
	}, [conversation?.meta?.topic, conversation?.messages]);

	const handleChange = (value, index) => {
		setProcessCall(prev => {
			let cloneProcess = lod_.cloneDeep(prev);
			cloneProcess.solutions[index].value = value;
			return cloneProcess;
		});
	};

	return (
		<Box style={{ padding: "5px" }}>
			<Card>
				<CardHeader
					style={{ paddingBottom: "1px" }}
					avatar={<LibraryBooksIcon />}
					title={`${i18n.t("COC.convCallProcess")} pour ${getLabelOf(conversation?.meta?.topic)}`}
				/>

				<CardContent className={classes.AccordionDetails}>
					{!lod_.isNil(processCall) && !lod_.isEmpty(processCall) ? (
						<Box style={{ display: "flex", flexDirection: "column" }}>
							<Accordion className="classAccordeonTabInfo" style={{ marginBottom: "2%" }}>
								<AccordionSummary
									expandIcon={<ExpandMoreIcon />}
									aria-controls="panel1a-content"
									id="panel1a-header"
								>
									<Typography variant="h6">Argumentaire : </Typography>
								</AccordionSummary>
								<AccordionDetails>
									<Box style={{ padding: "1%" }}>
										<Typography variant="body1" style={{ whiteSpace: "pre-line" }}>
											{processCall?.argument}
										</Typography>
									</Box>
								</AccordionDetails>
							</Accordion>

							{!lod_.isNil(processCall?.solutions) && !lod_.isEmpty(processCall?.solutions) && (
								<Accordion className="classAccordeonTabInfo" style={{ marginBottom: "2%" }}>
									<AccordionSummary
										expandIcon={<ExpandMoreIcon />}
										aria-controls="panel1a-content"
										id="panel1a-header"
									>
										<Typography variant="h6">Solution(s) : </Typography>
									</AccordionSummary>
									<AccordionDetails>
										<Box style={{ padding: "1%" }}>
											{processCall.solutions.map((process, key) => (
												<Box key={key} style={{ display: "flex", flexDirection: "column" }}>
													<Typography variant="body1">
														{key + 1} - {process.text}
													</Typography>
													<FormControl component="fieldset">
														<RadioGroup
															row
															aria-label="gender"
															name="gender1"
															value={process.value}
															onChange={e => handleChange(JSON.parse(e.target.value), key)}
														>
															<FormControlLabel value={true} control={<Radio />} label="Accepté" />
															<FormControlLabel value={false} control={<Radio />} label="Refusé" />
														</RadioGroup>
													</FormControl>
												</Box>
											))}
										</Box>
									</AccordionDetails>
								</Accordion>
							)}

							{!lod_.isNil(processCall?.actionsProcess) &&
								!lod_.isNil(valueContext) &&
								!lod_.isEmpty(valueContext) &&
								!lod_.isNil(dictionary?.items) &&
								!lod_.isEmpty(dictionary?.items) && (
									<Accordion className="classAccordeonTabInfo">
										<AccordionSummary
											expandIcon={<ExpandMoreIcon />}
											aria-controls="panel1a-content"
											id="panel1a-header"
										>
											<Typography variant="h6">Action(s)</Typography>
										</AccordionSummary>
										<AccordionDetails>
											<Typography style={{ paddingLeft: "1%" }}>
												{processCall?.actionsProcess?.title}
											</Typography>
											<Box style={{ padding: "1%" }}>
												<Box style={{ maxHeight: 200, maxWidth: "100%", overflow: "auto" }}>
													<DataGrid
														rows={valueContext.map((value, index) => ({
															id: value?.id ?? index + 2,
															...value
														}))}
														columns={columns}
														autoHeight
														disableColumnFilter
														checkboxSelection
														hideFooter
														hideFooterRowCount
														hideFooterSelectedRowCount
														onSelectionModelChange={selection => setRowSelected(selection)}
													/>
												</Box>

												<Box style={{ paddingTop: "1%" }}>
													<Typography style={{ paddingBottom: "1%" }}>
														Choix de la date :
													</Typography>
													<MuiPickersUtilsProvider locale={fr} utils={DateFnsUtils}>
														<KeyboardDatePicker
															inputVariant="outlined"
															fullWidth
															format="dd/MM/yyyy"
															label="DATE"
															value={dateForAction}
															onChange={e => setDateForAction(e)}
															disableToolbar
															onError={() => null}
															helperText=""
														/>
													</MuiPickersUtilsProvider>
												</Box>
												<Box style={{ display: "flex", flexDirection: "column" }}>
													<Button
														style={{ marginTop: "1%" }}
														color="primary"
														variant="contained"
														onClick={() => console.log(dateForAction)}
													>
														{i18n.t("COC.validate")}
													</Button>
												</Box>
											</Box>
										</AccordionDetails>
									</Accordion>
								)}
						</Box>
					) : (
						<Typography>Aucun processus a été trouvé pour cette intention </Typography>
					)}
				</CardContent>
			</Card>
		</Box>
	);
}
